<template>
  <div class="d-flex align-items-center">
    <div v-for="(approval, index) in approvalsWithUsers" :key="approval.approvedBy">
      <div :class="{ overlapping: index !== 0 }">
        <ApproverAvatar
          v-if="approval.approvedByUser"
          :approval="approval"
          @delete-approval="$emit('delete-approval')"
        />
      </div>
    </div>
    <div
      v-if="showApproveAction && hasReconciliationApprove"
      class="approve-button"
      :class="{ overlapping: approvalsWithUsers.length > 0 }"
    >
      <ApproveCheckButton @create-approval="$emit('create-approval')" />
    </div>
  </div>
</template>

<script type="text/javascript">
import { computed } from 'vue';

import { useUser } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';

import ApproverAvatar from './ApproverAvatar';
import ApproveCheckButton from './ApproveCheckButton';

export default {
  name: 'ApproversAvatars',
  components: { ApproverAvatar, ApproveCheckButton },
  props: {
    approvals: { type: Array, default: () => [] },
    users: { type: Object, required: true },
  },
  setup(props) {
    const { user } = useUser();
    const { hasReconciliationApprove } = useGlobalPermissions();
    const currentUserApproval = computed(() =>
      props.approvals?.find((approval) => approval.approvedBy === user.value?.id)
    );

    const approvalsWithUsers = computed(() => {
      const sortedApprovals = props.approvals?.filter((approval) => approval.approvedBy !== user.value?.id) || [];
      if (currentUserApproval.value) sortedApprovals.push(currentUserApproval.value);
      return sortedApprovals.map((approval) => ({
        ...approval,
        approvedByUser: props.users[approval.approvedBy],
      }));
    });

    const showApproveAction = computed(() => !currentUserApproval.value);

    return {
      hasReconciliationApprove,
      approvalsWithUsers,
      currentUserApproval,
      showApproveAction,
    };
  },
};
</script>
<style lang="scss" scoped>
.overlapping {
  margin-right: -2px;
}

.approve-button {
  visibility: hidden;
}

tr:hover .approve-button {
  visibility: visible;
}
</style>
