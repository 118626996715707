<template>
  <el-tooltip placement="top" :enterable="false" :content="$t('payment.paymentTable.tooltips.markAsApproved')">
    <div
      class="d-flex rounded-pill justify-content-center align-items-center rounded-div-container"
      @click.prevent.stop="$emit('create-approval')"
    >
      <CheckIcon width="10" />
    </div>
  </el-tooltip>
</template>
<script type="text/javascript">
import { CheckIcon } from '@/assets/icons';
export default {
  name: 'ApproveCheckButton',
  components: { CheckIcon },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.rounded-div-container {
  height: 24px;
  width: 24px;
  outline: 1px solid #bbbec2;
  position: relative;
  background-color: white;
  cursor: pointer;

  &:hover {
    outline: 2px solid white;
    background-color: #d2d4d7;
  }
}
</style>
