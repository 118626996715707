<template>
  <el-tooltip placement="top" :enterable="false">
    <div
      class="d-flex rounded-pill justify-content-center align-items-center rounded-div-container"
      :class="{ 'current-approver': approval.approvedBy === user.id }"
      @click.prevent.stop="handleClick"
    >
      <div v-if="approval.approvedByUser.profilePictureUrl" class="rounded-pill">
        <img :src="approval.approvedByUser.profilePictureUrl" :width="24" :height="24" />
      </div>
      <div
        v-else
        class="bg-primary rounded-pill d-flex justify-content-center align-items-center initials-pill"
        :class="{ 'current-approver': approval.approvedBy === user.id }"
      >
        <small class="text-uppercase text-white fs-bold initials-text">{{
          approval.approvedByUser.firstName[0] + approval.approvedByUser.lastName[0]
        }}</small>
      </div>
    </div>
    <div slot="content">
      <div class="tooltip-text name">
        {{ `${approval.approvedByUser.firstName} ${approval.approvedByUser.lastName}` }}
      </div>
      <div class="tooltip-text">{{ formatDate(approval.approvedAt) }}</div>
    </div>
  </el-tooltip>
</template>

<script type="text/javascript">
import { DateTime } from 'luxon';

import { useUser } from '@/modules/auth';

export default {
  name: 'ApproverAvatar',
  props: {
    approval: { type: Object, required: true },
  },
  setup() {
    const { user, token } = useUser();

    return { user, token };
  },
  methods: {
    formatDate(ms) {
      return ms ? DateTime.fromMillis(ms).toFormat('dd.LL.yyyy, HH:mm') : '';
    },
    handleClick() {
      if (this.user.id !== this.approval.approvedBy) return;
      this.$emit('delete-approval');
    },
    imgSize() {
      return this.user.id === this.approval.approvedBy ? 22 : 24;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.tooltip-text {
  font-size: $font-size-small;

  &.name {
    font-weight: $font-weight-bold;
  }
}

.rounded-div-container {
  height: 1.5rem;
  width: 1.5rem;
  box-shadow: 0 0 0 2px white;
  position: relative;
  border-radius: 50%;
  cursor: default;
}

.rounded-div-container.current-approver {
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
}

.rounded-div-container.current-approver:not(:hover) {
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    border: 1px solid white;
    box-shadow: 0 0 0 1.5px #00b27a, 0 0 0 2.5px white;
    border-radius: 50%;
  }
}

.rounded-div-container.current-approver:hover {
  box-shadow: 0 0 0 2px white;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 2px;
    background-color: white;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::before {
    z-index: 1;
  }

  img,
  div {
    filter: brightness(70%);
  }
}

.initials-pill {
  height: 1.5rem;
  width: 1.5rem;
}
.initials-text {
  font-size: 10px;
}
</style>
