<template>
  <div>
    <DashboardCard
      :loading="loading"
      :title="$t('payment.annualPaymentOverview.header')"
      :action-type="CARD_ACTIONS.DATEPICKER"
      :date-filter="yearFilter"
      :date-picker-type="'year'"
      @on-date-picker-date-change="datePickerDataChangeHandler"
    >
      <div v-loading="loading" class="pt-1 w-100">
        <BarChart :chart-data="chartChunks" :height="110" :width="350" :show-legend="false" />
        <div class="chart-legend-wrapper d-flex gap-1">
          <div v-for="(dataset, index) in chartChunks.datasets" :key="index" class="d-flex">
            <div class="chart-legend-indicator" :style="{ backgroundColor: dataset.backgroundColor }" />
            <div class="text-typography-secondary chart-legend-indicator-text">
              {{ dataset.label }}
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';

import typography from '@/stylesheets/scss/typography.module.scss';
import colors from '@/stylesheets/scss/colors.module.scss';
import { DashboardCard, CARD_ACTIONS } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { BarChart } from '../../components';
import { usePaymentsByMonth } from '../../compositions';

export const OVERVIEW_DATA_TYPE = {
  WITHOUT_DUE_TERM: 'withoutDueTerm',
  OVERDUE: 'overdue',
  FUTURE_PAYMENTS: 'futurePayments',
  PAID: 'paid',
  IN_PROGRESS: 'inProgress',
};
export default {
  components: { BarChart, DashboardCard },
  props: {
    loading: { type: Boolean, default: false },
    paymentsInProgress: { type: Array, default: () => [] },
    reconciliations: { type: Array, default: () => [] },
    paymentTerms: { type: Array, default: () => [] },
    customer: { type: Object, required: true },
    supplier: { type: Object, default: null },
  },
  setup(props) {
    const { formatToCurrency } = useCurrency();
    const yearFilter = ref(DateTime.local().toJSDate());
    const fromDate = computed(() => DateTime.fromJSDate(yearFilter.value).startOf('year').toISODate());
    const toDate = computed(() => DateTime.fromJSDate(yearFilter.value).endOf('year').toISODate());

    const { payments: nonFilteredPayments, loading: paymentsLoading } = usePaymentsByMonth(
      computed(() => ({
        businessId: props.customer.id,
        fromDate: fromDate.value,
        toDate: toDate.value,
      })),
      computed(() => ({ enabled: !!props.customer.id && !!fromDate.value && toDate.value }))
    );
    const payments = computed(() =>
      nonFilteredPayments.value.filter(({ supplierId }) => !props.supplier?.id || supplierId === props.supplier.id)
    );

    const datePickerDataChangeHandler = (monthPickerDate) => (yearFilter.value = new Date(monthPickerDate));

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      formatMoney,
      currentDate: ref(new Date()),
      yearFilter,
      payments,
      paymentsLoading,
      fromDate,
      toDate,
      datePickerDataChangeHandler,
      CARD_ACTIONS,
      months: computed(() =>
        Array.from({ length: 12 }, (_, i) =>
          DateTime.fromISO(toDate.value).plus({ month: 1 }).minus({ months: i }).toFormat('yyyy-LL')
        )
      ),
    };
  },

  computed: {
    inProgress() {
      return this.months.map((month) => {
        const currentMonthPayments = this.paymentsInProgress.filter(({ date }) => date.startsWith(month));
        return currentMonthPayments.reduce((total, { amount, isRefund }) => total + amount * (isRefund ? -1 : 1), 0);
      });
    },
    paidPayments() {
      return this.months.map((month, index) => {
        const currentMonthPayments = this.payments.find(({ date }) => date === month);
        return currentMonthPayments ? currentMonthPayments.amount - this.inProgress[index] : 0;
      });
    },
    paymentImbalancesWithDueTerm() {
      return this.reconciliations
        .map((reconciliation) => ({
          ...reconciliation,
          paymentTerm: this.paymentTerms.find(({ supplierId }) => supplierId === reconciliation.supplierId),
          totalBilledAmount: reconciliation.billedAmounts.reduce((acc, billedAmount) => acc + billedAmount.amount, 0),
          totalPaidAmount: reconciliation.paidAmounts.reduce((acc, paidAmount) => acc + paidAmount.amount, 0),
        }))
        .filter(({ paymentTerm }) => paymentTerm && Number.isInteger(paymentTerm.paymentDueNet));
    },
    overdue() {
      const overdue = this.paymentImbalancesWithDueTerm.filter(
        ({ paymentDueDate }) => new Date(paymentDueDate) < this.currentDate
      );
      const totalOverdueAmount = overdue.reduce(
        (total, { totalBilledAmount, totalPaidAmount }) => total + (totalBilledAmount - totalPaidAmount),
        0
      );

      return this.months.map((month) =>
        DateTime.fromJSDate(this.currentDate).toFormat('yyyy-LL') === month ? totalOverdueAmount : 0
      );
    },
    futurePayments() {
      const futurePayments = this.paymentImbalancesWithDueTerm.filter(
        ({ paymentDueDate }) => new Date(paymentDueDate) >= this.currentDate
      );

      return this.months.map((month) => {
        const currentMonthPayments = futurePayments.filter(
          ({ paymentDueDate }) => DateTime.fromISO(paymentDueDate).toFormat('yyyy-LL') === month
        );
        const totalFutureAmount = currentMonthPayments.reduce(
          (total, { totalBilledAmount, totalPaidAmount }) => total + (totalBilledAmount - totalPaidAmount),
          0
        );
        return totalFutureAmount ? totalFutureAmount : 0;
      });
    },
    chartChunks() {
      return {
        labels: this.getLabels(),
        datasets: this.getDataSets(),
      };
    },
  },
  methods: {
    getLabels() {
      const labels = this.months.map((month) => new Date(month).toLocaleString(this.$i18n.locale, { month: 'short' }));
      return this.$direction === 'rtl' ? labels : labels.reverse();
    },
    getDataSets() {
      return [
        {
          label: this.$t(`payment.annualPaymentOverview.labels.${OVERVIEW_DATA_TYPE.PAID}`),
          data: this.paidPayments,
          backgroundColor: colors.success,
          barThickness: 12,
          borderColor: 'rgba(0,0,0,0)',
        },
        {
          label: this.$t(`payment.annualPaymentOverview.labels.${OVERVIEW_DATA_TYPE.IN_PROGRESS}`),
          data: this.inProgress,
          backgroundColor: colors.informative,
          barThickness: 12,
          borderColor: 'rgba(0,0,0,0)',
        },
        {
          label: this.$t(`payment.annualPaymentOverview.labels.${OVERVIEW_DATA_TYPE.OVERDUE}`),
          data: this.overdue,
          backgroundColor: colors.error,
          barThickness: 12,
          borderColor: 'rgba(0,0,0,0)',
        },
        {
          label: this.$t(`payment.annualPaymentOverview.labels.${OVERVIEW_DATA_TYPE.FUTURE_PAYMENTS}`),
          data: this.futurePayments,
          backgroundColor: typography.typographyDisabled,
          barThickness: 12,
          borderColor: 'rgba(0,0,0,0)',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-legend-indicator {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.5rem;
  margin-top: 5px;
}

.chart-legend-indicator-text {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
}
</style>
